.selected-item {
    text-align: center;
}

.controls-container {
    --highlight-width: auto;
    --highlight-height: auto;
    --highlight-x-pos: 0;

    display: flex;
}

.controls {
    background-color: #edeff5;
    display: inline-flex;
    justify-content: space-between;
    border-radius: 5px;
    width: 100%;
    max-width: 200px;
    padding: 3px;
    overflow: hidden;
    position: relative;
}

.controls input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 28px;
}

.segment {
    width: 100%;
    position: relative;
    text-align: center;
    z-index: 1;
    height: 28px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.segment label {
    color: #a7a7a7cc;
    cursor: pointer;
    display: block;
    font-weight: 500;

    align-items: center;
    transition: color 0.5s ease;
}

.segment.active label {
    color: #000;
}

.controls::before {
    content: '';
    background-color: #b2c9df;
    border-radius: 4px;
    width: var(--highlight-width);
    height: var(--highlight-height);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    /*top: 8px;*/
    /*bottom: 8px;*/
    left: 0;
    z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
}
