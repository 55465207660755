html {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    background-color: #f2f4fa;
}

#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    text-decoration: none;
}

div[role='dialog'] {
    border-radius: 4px;
}

.ub-b-top_1px-solid-transparent {
    border-top: 1px solid #d8dae5 !important;
}

.ub-b-rgt_1px-solid-transparent {
    border-right: 1px solid #d8dae5 !important;
}

.ub-b-lft_1px-solid-transparent {
    border-left: 1px solid #d8dae5 !important;
}

.ub-b-btm_1px-solid-transparent {
    border-bottom: 1px solid #d8dae5 !important;
}
