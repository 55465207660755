.react-datepicker__aria-live {
    display: none;
}

.react-datepicker__current-month {
    width: max-content;
    font-weight: 700;
    margin: 5px auto;
}

.react-datepicker__navigation {
    border-radius: 9999px;
    background: #c1c4d6;
    cursor: pointer;
}

.react-datepicker__navigation:hover {
    color: #5ec090;
}

.react-datepicker__month {
    display: flex;
    flex-direction: column;
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
    justify-content: space-evenly;
    gap: 2px 0;
    margin-bottom: 5px;
}

.react-datepicker__day-name,
.react-datepicker__day {
    width: 20px;
    border-radius: 4px;
    padding: 4px;
    border: 1px solid transparent;
    text-align: center;
    background-color: #c1c4d633;
}

.react-datepicker__day-names {
    background-color: #c1c4d650;
    border-radius: 4px;
}

.react-datepicker__day-name {
    background-color: transparent;
}

.react-datepicker__day--today {
    border: 1px solid #5ec090;
}

.react-datepicker__day--outside-month {
    background-color: #c1c4d610;
    color: #101840;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range-start {
    background: #5ec090;
    color: white;
}

.react-datepicker__day--in-selecting-range {
    background: #5ec09050;
}

.react-datepicker__day--disabled {
    background-color: white;
    color: lightgray;
}

.react-datepicker__day:hover {
    background: #5ec090;
    color: white;
    border-color: #5ec090;
    transition: all 0.1s ease-in;
    cursor: pointer;
}

.react-datepicker__day--disabled:hover {
    background-color: white;
    color: lightgray;
    border-color: transparent;
    cursor: not-allowed;
}
.react-datepicker__week {
    display: flex;
}

.calendar {
    padding: 3px;
    background: white;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(67, 90, 111, 0.3);
    border-radius: 4px;
    color: #101840;
    font-size: 14px;
    z-index: 10;
}
